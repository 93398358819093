import React, { useState, useEffect } from 'react';
import '../../styles/RouletteGame.css'; // Custom styles
import { Wheel } from 'react-custom-roulette';
import { fetchUser, spinWheel, charge, reCharge } from '../utils/transactionUtils';
import { useNavigate } from 'react-router-dom';

const RouletteGame = () => {
  const navigate = useNavigate();
  const initialData = [
    { option: '0.1 TRX', style: { backgroundColor: '#4CAF50', textColor: 'white' }, prizeAmount: 0.1 },
    { option: '0.2 TRX', style: { backgroundColor: '#FFEB3B', textColor: 'black' }, prizeAmount: 0.2 },
    { option: '0.3 TRX', style: { backgroundColor: '#FFA500', textColor: 'black' }, prizeAmount: 0.3 },
    { option: '0.5 TRX', style: { backgroundColor: '#FF6347', textColor: 'white' }, prizeAmount: 0.5 },
    { option: '0.8 TRX', style: { backgroundColor: '#4682B4', textColor: 'white' }, prizeAmount: 0.8 },
    { option: '1.3 TRX', style: { backgroundColor: '#8A2BE2', textColor: 'white' }, prizeAmount: 1.3 },
    { option: '2.1 TRX', style: { backgroundColor: '#20B2AA', textColor: 'black' }, prizeAmount: 2.1 },
    { option: '3.4 TRX', style: { backgroundColor: '#FF1493', textColor: 'white' }, prizeAmount: 3.4 },
    { option: '5.5 TRX', style: { backgroundColor: '#00008B', textColor: 'white' }, prizeAmount: 5.5 },
    { option: 'Bomb', style: { backgroundColor: '#f44336', textColor: 'white', fontWeight: 'bold' }, prizeAmount: 0 },
  ];

  const [data, setData] = useState(initialData);
  const [balance, setBalance] = useState(0);
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [prizeText, setPrizeText] = useState('');
  const [error, setError] = useState('');
  const [cost, setCost] = useState(1); // Initial cost
  const fibonacciSequence = [1, 1, 2, 3, 5, 8, 13, 21, 34, 55];
  const [currentFibIndex, setCurrentFibIndex] = useState(0);

  const [chargeAmount, setChargeAmount] = useState(''); // Input value for charge amount
  const [withdrawAmount, setWithdrawAmount] = useState(''); // Input value for withdraw amount
  const [showChargeDialog, setShowChargeDialog] = useState(true); // Show charge dialog toggle
  const [showWithdrawDialog, setShowWithdrawDialog] = useState(false); // Withdraw dialog toggle

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await fetchUser();
        if (userResponse.success) {
          const balanceResponse = userResponse.data.gameBalance;
          setBalance(balanceResponse);
        } else {
          alert('Failed to fetch user data: ' + userResponse.error.message);
        }
      } catch (err) {
        console.error(err);
        alert('Error fetching user data: ' + err.message);
      }
    };

    fetchUserData();
  }, []);

  const updateDataBasedOnCost = (newCost) => {
    const multiplier = newCost; // Base cost is 0.1
    const updatedData = initialData.map((entry) => {
      if (entry.option !== 'Bomb') {
        const basePrize = entry.prizeAmount;
        const newPrize = basePrize * multiplier;
        return {
          ...entry,
          option: `${newPrize.toFixed(1)} TRX`,
          prizeAmount: newPrize,
        };
      }
      return entry;
    });
    setData(updatedData);
  };

  const handleIncrementCost = () => {
    if (currentFibIndex < fibonacciSequence.length - 1) {
      const newIndex = currentFibIndex + 1;
      const newCost = fibonacciSequence[newIndex];
      setCurrentFibIndex(newIndex);
      setCost(newCost);
      updateDataBasedOnCost(newCost);
    }
  };

  const handleDecrementCost = () => {
    if (currentFibIndex > 0) {
      const newIndex = currentFibIndex - 1;
      const newCost = fibonacciSequence[newIndex];
      setCurrentFibIndex(newIndex);
      setCost(newCost);
      updateDataBasedOnCost(newCost);
    }
  };

  const handleSpinClick = async () => {
    setError('');
    try {
      if (balance < cost) {
        setError('insuficient balance');
        setShowChargeDialog(true); // Show charge dialog when balance is insufficient
        return;
      }
      const spinResponse = await spinWheel(cost);
      if (spinResponse.success) {
        setPrizeNumber(spinResponse.data.prizeIndex);
        setMustSpin(true);
        setBalance((prevBalance) => prevBalance - cost); // Deduct cost after a successful spin
      } else {
        if (spinResponse.error.statusCode === 403) {
          setError('Please login');
        } else {
          setError(spinResponse.error.message);
        }
      }
    } catch (err) {
      setError(err.message);
    }
  };

  const handleStopSpinning = () => {
    const prize = data[prizeNumber].option;
    if (prize === 'Bomb') {
      setPrizeText('💥 Boom! You hit a Bomb! Try Again! 💥');
    } else {
      const prizeAmount = data[prizeNumber].prizeAmount;
      setBalance((prevBalance) => prevBalance + prizeAmount);
      setPrizeText(`🎉 Congratulations! You won: ${prizeAmount} TRX 🎉`);
    }
    setMustSpin(false);
  };

  const handleCharge = async () => {
    const amount = parseFloat(chargeAmount);

    // Validate the amount entered by the user
    if (isNaN(amount) || amount <= 0) {
        alert('Please enter a valid amount.');
        return;
    }

    try {
        // Call the charge function to process the deposit
        const res = await charge(amount);

        // Check if the response indicates success
        if (res.success) {
            alert(res.message || 'Balance charged successfully!');
            
            // Update the local balance
            setBalance((prevBalance) => prevBalance + amount);

            // Close the charge dialog
            setShowChargeDialog(false);
        } else {
            // If the charge failed, show the error message
            alert('Error charging balance: ' + (res.error?.message || 'Unknown error'));
        }
    } catch (err) {
        // Handle any unexpected errors
        alert('Error charging balance: ' + (err.message || 'Unknown error'));
    }
};



  // Withdraw functionality
  const handleWithdraw = async () => {
    const amount = parseFloat(withdrawAmount);
    if (isNaN(amount) || amount <= 0 || amount > balance) {
      alert('Please enter a valid amount.');
      return;
    }
    try {
      const res = await reCharge(amount);
      if (res.success) {
        alert('Withdrawal successful!');
        setBalance(balance - amount); // Update balance locally
        setShowWithdrawDialog(false); // Hide the dialog
      } else {
        alert('Withdrawal failed: ' + res.error.message);
      }
    } catch (err) {
      alert('Error during withdrawal: ' + err.message);
    }
  };

  const handleCancel = () => {
    setShowChargeDialog(false);
    setShowWithdrawDialog(false);
  };

  return (
    <div className="roulette-container">
       {/* Charge Dialog */}
       {showChargeDialog && (
        <div className="modal">
          <div className="modal-content">
            <h3>Charge Your Balance</h3>
            <p>Enter the amount to charge:</p>
            <input
              type="number"
              value={chargeAmount}
              onChange={(e) => setChargeAmount(e.target.value)}
              placeholder="Amount (TRX)"
              className="charge-input"
            />
            <div className="modal-buttons">
              <button onClick={handleCharge} className="charge-button">Charge</button>
              <button onClick={handleCancel} className="cancel-button">Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Withdraw Dialog */}
      {showWithdrawDialog && (
        <div className="modal">
          <div className="modal-content">
            <h3>Withdraw Funds</h3>
            <p>Enter the amount to withdraw:</p>
            <input
              type="number"
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(e.target.value)}
              placeholder="Amount (TRX)"
              className="withdraw-input"
            />
            <div className="modal-buttons">
              <button onClick={handleWithdraw} className="withdraw-button">Withdraw</button>
              <button onClick={handleCancel} className="cancel-button">Cancel</button>
            </div>
          </div>
        </div>
      )}
      <h1 className="roulette-title">🎉 Spin & Win Big! 🎉</h1>
      <div className="balance-container">
        <span>Balance: {balance.toFixed(2)} TRX</span>
        <button onClick={() => setShowWithdrawDialog(true)} className="withdraw-dialog-button">Withdraw</button>
      </div>
      <div className="cost-container">
        <button onClick={handleDecrementCost} disabled={currentFibIndex === 0}>
          -
        </button>
        <span>Cost per spin: {cost.toFixed(2)} TRX</span>
        <button onClick={handleIncrementCost} disabled={currentFibIndex === fibonacciSequence.length - 1}>
          +
        </button>
      </div>
      {error && <div className="error-message">{error}</div>}
      <Wheel
        mustStartSpinning={mustSpin}
        prizeNumber={prizeNumber}
        data={data}
        onStopSpinning={handleStopSpinning}
        spinDuration={0.5}
      />
      <button
        className="spin-button"
        onClick={handleSpinClick}
        disabled={mustSpin}
      >
        Spin the Wheel
      </button>
    </div>
  );
};

export default RouletteGame;
